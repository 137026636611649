import React from "react"
import { Row, Card} from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"


export default function Staff(props) {

    const tristan = "../images/Tristan.jpg"

  return (
	  <>
	  <section>
        <h1 className="text-light pt-5">Team</h1>
        <Row>
            <Card className="shadow mb-5 center me-1 ms-1 bg-transparent">
            <Card.Header><h3>Tristan</h3></Card.Header>
			      <StaticImage
                    src={tristan}
                    alt="Tristan Instructor"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
             />
             <Card.Body>
                 <Card.Title className="mb-2 text-light">Freediver Instructor</Card.Title>
                 <Card.Subtitle className="mt-2 text-light">AIDA Master Instructor </Card.Subtitle>
                 <Card.Text className="mb-1">
                 <br></br>
                 <p>
                   Born on the seaside in France (Brittany), Tristan was used to swim before to know how to walk.
                 </p>
                 <p>
                   After years working around the world for a well known medical NGO, Tristan decided to turn his passion for freediving into his job.
                 </p>
                 <p>
                   AIDA master instructor and competitor Tristan will teach you how to breathe properly, how to control your thoughts, and how to evolve safely to enjoy your time underwater.
                 </p>
                 </Card.Text>
                 <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:info@freedivingtenerife.life">info@freedivingtenerife.life</a></Card.Subtitle>
                 <Card.Subtitle className="mt-2 text-light">Tel : <a href="tel:+33606891851">+33 6 06 89 18 51</a></Card.Subtitle>
             </Card.Body>
			</Card>
       </Row>
		</section>
		</>
    )
}

import React from 'react'

const Details = () => (
  <>
    <div className='text-light'>
    <h3 className='pt-5'>Address</h3>
      <p>Piscina natural de Tabaiba baja, Tenerife</p>
      <p><h3>Phone</h3>(+33)6 06 89 18 51</p>
      <h3>Email</h3><a href='mailto:info@freedivingtenerife.life'>info@freedivingtenerife.life</a>
    </div>
  </>
)
export default Details

import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida1Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
          <h3><u>AIDA 2</u></h3>
          <p>Prerequisites</p>
          <li>Be 18 years of age or older (16 or 17 years old with parent or guardian consent)</li>
          <li>Can swim at least 200m non-stop without fins or at least 300m non-stop with mask, fins and snorkel</li>
          <h5>Requirements:</h5>
          <li>2 minutes STA; 40 meters DYN, 12 meters CWT, theoretical exam</li>
          <li>The static and dynamic sessions in confined water are used to teach relaxation, breathing, finning and safety techniques.</li>
          <li>In the open water sessions students will apply the skills they have learned in the pool / confined water sessions and combine them with the basic skills of open water freediving such as equalizing, duck diving, vertical swimming, body positioning, turns and use of buoyancy.</li><br/>
         	<h5>The freediving theory will include</h5>
          <li>Introduction to Freediving</li>
          <li>Freediving Breathing Cycle</li>
          <li>Basic Physiology of Freediving</li>
          <li>Equalization, Freediving Techniques</li>
          <li>Safety in Freediving</li>
          <li>Equipment in Freediving</li>
          <li>Freediving Disciplines</li><br/>
          <h5>Package Include :</h5>
          <li>Freediving insurance DAN</li>
          <li>Certification fee</li>
          <li>Administration fee</li>
          <li>Education materials fee</li>
        </Card.Body>
      </Card>
    </>
  )
}

import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida1Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
          <h3><u>AIDA 1</u></h3>
          <p>
            This course offers a basic knowledge of freediving and introductory skills such as relaxation of body and mind,
            finning techniques, duck dives and equalization, open water and pool disciplines.
          </p>
        	<h5>Package Include :</h5>
          <li>Freediving gear </li>
          <li>Pool fees </li>
          <li>Insurance </li>
          <li>Certification fee </li>
          <li>Administration fee </li>
          <li>Education materials fee </li>
        </Card.Body>
      </Card>
    </>
  )
}

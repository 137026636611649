import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';

export default function Toasty({children}){

  const [show, toggleShow] = useState(false);
  const toastyStyle = {
    position: 'relative',
    backgroundColor: "transparent",
    zIndex: 2,
    minWidth: "100%",
  }
 
  return (
    <>
      {!show && <Button onClick={() => toggleShow(true)}>{children.props.toastyHeader}</Button>}
      <Toast show={show} onClose={() => toggleShow(false)} style={toastyStyle}>
        <Toast.Header style={toastyStyle}>
          <strong className="me-auto">{children.props.toastyHeader}</strong>
        </Toast.Header>
        <Toast.Body>
            {children}
        </Toast.Body>
      </Toast>
    </> 
  );
  }

import React from "react"
import {Card} from 'react-bootstrap'
import { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Aida1Details from "./aida1Details"
import Aida2Details from "./aida2Details"
import Aida3Details from "./aida3Details"
import Aida4Details from "./aida4Details"



import Toasty from "./toasty"

const cardStyle = {
  backgroundColor: "transparent",
//   minWidth : "200px",
}

const cardStyleBig = {
    backgroundColor: "transparent",
    // width : "100%",
}

const bottomRight = {
    position: 'absolute',
    bottom: '-20px',
    right: '20px'
}
export default function AidaDetails2(props) {

    const [smallScreen, setSmallScreen] = useState(false)
    const aida1 = "../images/aida1.jpg"
    const aida2 = "../images/aida2.jpg"
    const aida3 = "../images/aida3.jpg"
    const aida4 = "../images/aida4.jpg"
    return (
        <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida1}
                    alt="Aida 1 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">AIDA 1 - Beginner</Card.Title>
                <Card.Text>
                  1 full day open water + pool + theory.<br/>
                  Price: 150€<br/>
                  Requirements: None.
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
                <Toasty>
                    <Aida1Details toastyHeader = "AIDA 1 Details Here !"/>
                </Toasty>
                <p className="row" style={bottomRight}>&emsp; 150&euro;</p>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida2}
                    alt="Aida 2 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">AIDA 2 - Freediver</Card.Title>
                <Card.Text>
                  3 days : including Full equipment, diving insurance, 1 pool session, 3 open water sessions, 3 theory classes.<br/>
                Price: 370€<br/>
                Can be directly taken even without having an AIDA 1 certification.
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>

                <Toasty>
                    <Aida2Details toastyHeader = "AIDA 2 Details Here !"/>
                </Toasty>
                <p className="row" style={bottomRight}>&emsp; 370&euro;</p>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida3}
                    alt="Aida 3 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light" >AIDA 3 - Advanced Freediver</Card.Title>
                <Card.Text>
                  AIDA 3 : 4 days including full equipment, diving insurance, 2 pool sessions, 4 open water sessions, 4 theory classes.<br/>
                  Price: 490€<br/>
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>

                <Toasty>
                    <Aida3Details toastyHeader = "AIDA 3 Details Here !"/>
                </Toasty>
                <p className="row" style={bottomRight}>&emsp; 490&euro;</p>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida4}
                    alt="Aida 4 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">AIDA 4 - Master Freediver</Card.Title>
                <Card.Text>
                Become A Master !<br/>
                Aide 4 : 4 days including full equipment, diving insurance, 2 pool sessions, 4 open water sessions, 4 theory classes. <br/>
                Price : 490€<br/>
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
              <Toasty>
                <Aida4Details toastyHeader = "AIDA 4 Details Here !"/>
              </Toasty>
              <p className="row" style={bottomRight}></p>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            </div>
            </>
  )
}

import React from "react"

export default function Home(props) {
  return (
    <section>
      <div className="mt-5">
        <h3>Welcome to Freediving Tenerife !</h3>
        <p>
          Freediving Tenerife will teach you how to hold your breath and how to practice apnea safely in the wonderful blue of the Canary islands. 
        </p>
        <p>
          You don’t need super power to practice apnea, freediving is accessible to everyone and I will unlock the true potential of your body and mind !
        </p>
        <p>
          In Tenerife We have perfect conditions to freedive 365 days a year with a visibility of at least 20m just a few swimming kicks from the shore !
        <br></br>
          Freediving has been my passion since i’m a kid. Come here and I'll be honored to share it with you !
        </p>
      </div>
    </section>
  )
}

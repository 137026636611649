import React from "react"
import heroPoster from "../images/hero_img.jpg"

export default function HeroVid(props) {

    if (props.littleScreen === "1"){
        return (
        <video poster={heroPoster}>
        </video>
        )
    }
    else {
        return (
            <video poster={heroPoster} muted autoPlay playsInline loop>
            //Video can be put here if desired
            </video>
         )
    }
}

import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida3Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
          <h3><u>AIDA 3</u></h3>
          <h5>Prerequisites:</h5>
           <li>Be 18 years of age or older (16 or 17 years old with parent or guardian consent)</li>
          <li>Can swim at least 200m non-stop without fins or at least 300m non-stop with mask, fins and snorkel</li>
          <li>Have completed the AIDA2 course or have completed the AIDA2 Crossover Evaluation if crossing over from another freediving agency</li><br/>
         <h5>Requirements:</h5>
         <p>2:45 minutes STA; 55 meters DYN, 24 meters CWT, theoretical exam</p>
         <p>Maximum : 30m deep</p>
           <p>The course is designed to further develop skills from previous levels, to acquire new skills and gain a higher knowledge of safety procedures and techniques.</p>
           <p>	The new techniques that are introduced are free-fall, Frenzel Equalizing, the use of training tables, the risks of increasing and decreasing pressure and also how to minimize these risks</p>.
           <p>	Students will train these skills in the most common disciplines of freediving: Static Apnoea, Dynamic Apnea, Free Immersion and Constant Weight.</p>
          <h5>The theory will include: </h5>
          <li>Physiology</li>
          <li>Equalization</li>
          <li>Barotrauma</li>
          <li>Lungs at Depth</li>
          <li>Buoyancy</li>
          <li>Shallow Water Blackout</li>
          <li>Training Concepts</li>
          <li>The Mammalian Dive Response</li>
          <li>Decompression Sickness</li>
          <li>Surface Intervals</li>
          <br/><h5>Package Include :</h5>
          <li>Certification fees</li>
          <li>Administration fees</li>
          <li>Education materials fees</li>
         </Card.Body>
      </Card>
    </>
  )
}
